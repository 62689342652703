<template>
    <!-- 多媒体消息 》多媒体消息模板管理 -->
    <div id="app_template_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    新建模板
                </el-button>
            </div>
            <div class="head_top_title">多媒体消息模板管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                <el-table :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="mmsid" label="模板编号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="title" label="标题" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="state" label="状态" min-width="80" align="center"></el-table-column>
                    <el-table-column prop="user_company_name" label="模板所属客户公司" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="操作" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="detail(scope.row.id)">详情</el-button>
                            <template v-if="scope.row.state == '审核中'">
                                <el-button type="text" size="mini" style="color:grey">删除</el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" size="mini" style="color:#f56c6c" @click="del(scope.row.id)">删除</el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>

        <!-- 新增多媒体模板 -->
        <MediaTemplateAdd :prop_change_i="prop_template_channel_i" :prop_channel_id="prop_template_channel_id"
        @add_template="add_template"></MediaTemplateAdd>

        <!-- 多媒体模板详情 -->
        <MediaTemplateDetail :prop_change_i="prop_template_detail_i" :prop_template_id="prop_template_detail_id"></MediaTemplateDetail>

    </div>
</template>
<script>
import API from '../../api/api';
import MediaTemplateAdd from './media_template_add.vue';//组件 多媒体消息新建模板
import MediaTemplateDetail from './media_template_detail.vue';//组件 多媒体模板详情

export default {

    components: {
        MediaTemplateDetail,
        MediaTemplateAdd
    },
    
    data(){
        return{
            content_heigth: 500,//内容的高度
            content_width: 500,//内容的宽度

            channe_id : 0,//通道id

            tableData: [],//表格数据

            //新建多媒体模板
            prop_template_channel_i: 0,//新建多媒体模板  组件 弹出框是否可见
            prop_template_channel_id: "",//新建多媒体模板  组件  通道id

            //多媒体模板详情
            prop_template_detail_i: 0,//多媒体模板详情  组件 弹出框是否可见
            prop_template_detail_id: "",//多媒体模板详情  组件  模板id

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.channe_id = this.$route.params.id;

        },

        //增加模板
        add(){
            this.prop_template_channel_id = this.channe_id;
            this.prop_template_channel_i ++;//弹出框是否可见
        },
        //模板详情
        detail(template_id){
            this.prop_template_detail_id = template_id;
            this.prop_template_detail_i ++;//弹出框是否可见
        },
        //添加模板后回调
        add_template(){
            this.getData();
        },

        //查询
        getData(){
            //请求接口
            API.MediaTemplateServlet({
                param: "mediaTemplateList",
                channel_id: this.channe_id,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },

        //删除模板
        del(id){
            this.$confirm('此操作将永久删除模板, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.MediaTemplateServlet({
                        param: "delMediaTemplateQF",
                        template_id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，模板删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
        //返回
        goBack() {
            API.router_to("/media");
        }
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>